import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject } from "rxjs";
import { LanguageService } from "src/app/services/language.service";

export const LANG_MODAL_ID = "LANG_MODAL";

@Component({
  selector: "app-lang-modal",
  templateUrl: "./lang-modal.component.html",
  styleUrls: ["./lang-modal.component.scss"],
})
export class LangModalComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  constructor(
    private _language: LanguageService,
    private _modal: NgxSmartModalService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  changeLang(lang: "en" | "es") {
    this._language.language$.next(lang);
    this.close();
  }

  close() {
    this._modal.get(LANG_MODAL_ID).close();
    this._modal.removeModal(LANG_MODAL_ID);
  }
}
