import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject, of } from "rxjs";
import { switchMap, tap, finalize, takeUntil } from "rxjs/operators";
import { LanguageService } from "src/app/services/language.service";
import { SuscripcionesService } from "src/app/services/suscripciones.service";

export const SUSCRIPTION_MODAL_ID = "SUSCRIPTION_MODAL";

@Component({
  selector: "app-subscription-modal",
  templateUrl: "./subscription-modal.component.html",
  styleUrls: ["./subscription-modal.component.scss"],
})
export class SubscriptionModalComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  private readonly data$ = of({}).pipe(
    tap(() => (this.loading = true)),
    switchMap(() => this._suscripciones.get()),
    finalize(() => (this.loading = false)),
    takeUntil(this.onDestroy$)
  );

  private readonly create$ = of({}).pipe(
    tap(() => ((this.requesting = true), (this.error = ""))),
    switchMap(() => {
      const lang = this._language.language$.value || "en";
      const errors = this.FORM_ERRORS[lang];
      if (this.form.controls["email"].errors) throw errors.email;
      if (!this.form.controls["condition"].value) throw errors.condition;
      const { email } = this.form.value;
      return this._suscripciones.create({ email, lang });
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  //Data
  descuento: any;
  data: any;

  //Status
  loading: boolean;
  requesting: boolean;
  error: string;

  //Form
  form: FormGroup;

  constructor(
    private _modal: NgxSmartModalService,
    private _suscripciones: SuscripcionesService,
    private fb: FormBuilder,
    private _language: LanguageService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      condition: [null],
    });
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getData() {
    this.data$.subscribe((descuento) => (this.descuento = descuento));
  }

  create() {
    this.create$.subscribe(
      (data) => {
        this.data = data;
      },

      (error) => {
        if (error && error.error && error.error.message)
          error = error.error.message;
        this.error = error;
      }
    );
  }

  getDescuento() {
    this.data$.subscribe((descuento) => (this.descuento = descuento));
  }

  close() {
    this._modal.get(SUSCRIPTION_MODAL_ID).close();
    this._modal.removeModal(SUSCRIPTION_MODAL_ID);
  }

  FORM_ERRORS = {
    es: {
      email: "Ingrese un correo válido.",
      condition: "Acepte mantenerse al día con nosotros.",
    },
    en: {
      email: "Enter a valid email.",
      condition: "Agree to keep up to date with us",
    },
  };
}
