export const environment = {
  production: false,
  apiUrl: "http://198.199.104.5:9252",
  maderasParent: ["Maple", "Birch", "Ash"],
  stripeMx: "pk_test_rxlpGOr2dBzGuc4yTLB79nZR00lfpSyS99",
  stripeUS: "pk_test_n8XZU6x4ZcotdVW0qy5NzlB900k162kbAx",
  customBatPrices: {
    en: { precio: 189, copa: 15, peso: 15, tiempo: 15 },
    es: { precio: 2840, copa: 400, peso: 400, tiempo: 150 },
  },
  shipping: {
    USA: {
      fast: {
        multiple: 19,
        single: 16,
      },
      regular: {
        multiple: 12,
        single: 9,
      },
    },
    MEX: {
      fast: {
        multiple: 342,
        single: 288,
      },
      regular: {
        multiple: 216,
        single: 162,
      },
    },
  },
};
