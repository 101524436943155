import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SuscripcionesService {
  private readonly LS_KEY = "OVERFLY_SUSCRIPCION";

  constructor(private http: HttpClient) {}

  create(data: { email: string; lang: string }) {
    return this.http.post(`${environment.apiUrl}/suscripciones`, data);
  }

  get() {
    return this.http.get(`${environment.apiUrl}/suscripciones/activa`);
  }

  get suscripcion() {
    return localStorage.getItem(this.LS_KEY);
  }

  set suscripcion(suscrito: string) {
    localStorage.setItem(this.LS_KEY, suscrito || "");
  }
}
