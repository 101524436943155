import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errorHandler'
})
export class ErrorHandlerPipe implements PipeTransform {

  transform(value: any): any {
    let rValue = '';
    if(value != null){
      switch (value) {
        case value['invalid']:
          
          break;
          case value['email']:
          
            break;
      
        default:
          break;
      }
    }
  }

}
