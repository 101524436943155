import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class CheckoutService {
  public isCheckout = false;
  public $subjectCheckout = new BehaviorSubject<boolean>(false);
  public $observerCheckout = this.$subjectCheckout.asObservable();
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  pagar(data) {
    return this.http
      .post(`${this.apiUrl}/checkout/pagar`, data)
      .pipe(catchError(this.handleError));
  }

  prepararPayment(data) {
    return this.http
      .post(`${this.apiUrl}/checkout/generarPayment`, data)
      .pipe(catchError(this.handleError));
  }

  verificarDescuento(codigo) {
    return this.http
      .get(`${this.apiUrl}/descuentos/verificar?codigo=${codigo}`)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
