import { SafeUrl } from "@angular/platform-browser";
import { Variante } from "src/app/shared/models/Variante.model";

export class CartItem {
  product: number;

  id: number;
  name: string;
  quantity: number;
  price: number;
  discount?: number;
  image: SafeUrl;
  variante?: Variante;
  stock: number;

  constructor(item: {
    product: number;
    id: number;
    name: string;
    quantity: number;
    price: number;
    discount?: number;
    image: SafeUrl;
    variante?: Variante;
    stock: number;
  }) {
    this.product = item.product;
    this.id = item.id;
    this.name = item.name;
    this.quantity = item.quantity;
    this.image = item.image;
    this.variante = item.variante || null;
    this.price = item.price;
    this.discount = item.discount || null;
    this.stock = item.stock;
  }

  get finalTotalPrice() {
    return this.itemFinalPrice * (this.quantity || 0);
  }

  get totalPrice() {
    return (this.price || 0) * (this.quantity || 0);
  }

  get totalDiscount() {
    return this.itemDiscount * (this.quantity || 0);
  }

  get itemFinalPrice() {
    return this.price - this.itemDiscount;
  }

  get itemDiscount() {
    return (this.price || 0) * ((this.discount || 0) / 100);
  }
}
