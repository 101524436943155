import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError, Observable, BehaviorSubject, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  apiUrl = environment.apiUrl;
  public batData;
  public lenguajeObservable$  = new BehaviorSubject<any>('');
  public lineasObservable$  = new Subject<any>();
  public lineas: any = [];
  public lineasMX: any = [];
  public lineasUSA: any = [];

  constructor(
    private http: HttpClient,
    private toastr: ToastrManager
  ) { }

  getMaderas() {
    return this.http.get(`${this.apiUrl}/maderas/getParents`).pipe(catchError(this.handleError));
  }

  getModelosPorLinea(linea, page) {
    return this.http.get(`${this.apiUrl}/modelos/getModelosPorLinea`, {
      params: {
        linea: linea,
        page: page
      }
    }).pipe(catchError(this.handleError));
  }
  getBat(id, linea){
    return this.http.get(`${this.apiUrl}/ecommerce/getBat`,{
      params: { id: id, linea: linea}
    }).pipe(catchError(this.handleError));
  }
  getRandomBats(lang){
    return this.http.get(`${this.apiUrl}/ecommerce/getRandomBats`, {
      params: {lang : lang}
    })
  }

  getLineas(filter?){
    if(!filter) filter = {};
    filter = JSON.stringify(filter);
    return this.http.get(`${this.apiUrl}/lineas/get`,{
      params: { filter }
    }).pipe(catchError(this.handleError));
  }

  getLinea(nombreUrl, lang) {
    return this.http.get(`${this.apiUrl}/lineas/getLinea`,{
      params: { nombreUrl, lang }
    }).pipe(catchError(this.handleError));
  }

  scrollTo(top: number){
    window.scrollTo({
      top: top,
      left: 0,
      behavior: 'smooth'
    });
  }

  getEstadosUS(): Observable<any>{
    return this.http.get('assets/json/estadosUS.json');
  }
  getEstadosMX(): Observable<any>{
    return this.http.get('assets/json/estadosMX.json');
  }

  initLineas() {
    this.getLineas({ where: { status: "activo" } }).subscribe(
      (lineas: any[]) => {
        this.lineasUSA = lineas.filter(l => l.ecommerce == 'USA');
        this.lineasMX = lineas.filter(l => l.ecommerce == 'MX');
        this.lineasObservable$.next();
      },
      (err) => {
        this.toastr.errorToastr("Error al cargar lineas.");
      }
    );
  }

  formatLineaUrl(nombre) {
    return nombre.trim().toLowerCase().replace(/ /g,"-");
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
        console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  getParametros(url) {
    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    // we'll store the parameters here
    var obj = {};
    // if query string exists
    if (queryString) {
      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];
      // split our query string into its component parts
      var arr = queryString.split('&');
      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');
        // set parameter name and value (use 'true' if empty)
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
        // (optional) keep case consistent
        paramName = paramName.toLowerCase();
        if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();
        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {
          // create key if it doesn't exist
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];
          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string'){
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }
    return obj;
  }
}
