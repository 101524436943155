import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ItemCartComponent } from './cart/item-cart/item-cart.component';
import { CartComponent } from './cart/cart.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RandomBatsComponent } from './random-bats/random-bats.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSmartModalModule,
    RouterModule,
    TranslateModule,
    PipesModule
  ],
  exports: [
    CartComponent,
    RandomBatsComponent
  ],
  declarations: [ItemCartComponent, CartComponent, RandomBatsComponent]
})
export class PartialsModule { }
