import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnChanges,
} from "@angular/core";
import { ItemCart } from "../../../interfaces/item-cart";
import { CartService } from "../../../services/cart.service";
import { Router } from "@angular/router";
import { StoreService } from "../../../services/store.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { CartService as CartStoreService } from "../../../services/cart/cart.service";
import { CartItem } from "../../../services/cart/classes/CartItem.class";
@Component({
  selector: "app-item-cart",
  templateUrl: "./item-cart.component.html",
  styleUrls: ["./item-cart.component.scss"],
})
export class ItemCartComponent implements OnInit {
  @Input() item: ItemCart;
  @Input() storeItem: CartItem;
  @Input() index;
  constructor(
    private cartService: CartService,
    public router: Router,
    private storeService: StoreService,
    private toastr: ToastrManager,
    public _cart: CartStoreService
  ) {}

  ngOnInit() {}

  addItem(item: ItemCart) {
    this.cartService.addItem(item);
  }

  removeItem() {
    this.cartService.removeItem(this.index);
  }

  irDetalle() {
    if (this.item.linea === "Custom") {
      return;
    }
    this.storeService.batData = {
      id: this.item.modeloId,
      linea: this.item.lineaId,
      nombre: this.item.modelo,
    };
    this.router.navigate([
      "/store",
      this.item.lineaId,
      this.item.modelo.toLowerCase().replace(/ /g, "-"),
      `?i=${this.item.modeloId}&l=${this.item.lineaId}&n=${this.item.modelo}`,
    ]);
  }

  goDetail(id: number) {
    this.router.navigate(['article', id])
  }
}
