import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Cart } from "./classes/Cart.class";
import * as CryptoJS from "crypto-js";
import { CartItem } from "./classes/CartItem.class";
@Injectable({
  providedIn: "root",
})
export class CartService {
  private ENC_KEY = "OVEFLY_CART_ENC";
  private LS_KEY = "OVEFLY_CART_LS";

  private cart$ = new BehaviorSubject<Cart>(this.get());
  public cartChanges$ = this.cart$.asObservable();

  get cart() {
    return this.cart$.value;
  }

  constructor() {
    this.init();
  }

  //Cart
  init() {
    this.cart$.subscribe((cart) => {
      this.save(cart);
    });
  }

  get isEmpty() {
    return !(this.cart && this.cart.items && this.cart.items.length);
  }

  remove() {
    this.cart$.next(new Cart([]));
  }

  //Items
  getItem(id: number) {
    return (this.cart.items || []).find(({ id: _id }) => _id == id);
  }

  addItem(item: CartItem) {
    const { items } = this.cart;
    //Find Item
    const itemIdx = items.findIndex(({ id }) => id == item.id);
    //If item exists add only qty else push new Cart item
    if (itemIdx > -1)
      items[itemIdx] = new CartItem({
        ...item,
        quantity: items[itemIdx].quantity + item.quantity,
      });
    else items.push(new CartItem(item));
    //Update Cart
    this.cart$.next(this.assign({ ...this.cart, items }));
  }

  removeItem(id: number, qty?: number) {
    const { items } = this.cart;
    //Find Item
    const itemIdx = items.findIndex(({ id: _id }) => id == _id);
    //If item doesn't exist return
    if (itemIdx < 0) return;
    //If has qty
    if (qty) items[itemIdx].quantity -= qty;
    //Remove item if no quantity
    if (!qty || items[itemIdx].quantity < 0) items.splice(itemIdx, 1);
    //Update Cart
    this.cart$.next(this.assign({ ...this.cart, items }));
  }

  updateItem(item: CartItem) {
    const { items } = this.cart;
    //Find Item
    const itemIdx = items.findIndex(({ id }) => id == item.id);
    //If item dosen't exist return
    if (itemIdx < 0) return;
    //Update item
    items[itemIdx] = item;
    //Update Cart
    this.cart$.next(this.assign({ ...this.cart, items }));
  }

  hasStock(id: number, quantity: number, stock: number) {
    const item = this.getItem(id);
    if (!item) return true;
    if (stock < item.quantity + quantity) return false;
    return true;
  }

  private assign(cart: Partial<Cart>) {
    //Transform cart Object to Class instance
    const transformed = Object.assign(new Cart(), cart);
    transformed.items = transformed.items.map((item) => new CartItem(item));
    return transformed;
  }

  private save(cart: Cart) {
    try {
      //Encrypt cart
      const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(cart),
        this.ENC_KEY
      );
      //Save cart
      localStorage.setItem(this.LS_KEY, encrypted.toString());
    } catch (error) {
      localStorage.setItem(this.LS_KEY, "{}");
    }
  }

  private get() {
    try {
      //Get encrypted cart from ls
      const encrypted = localStorage.getItem(this.LS_KEY);
      //Decrypt ls cart data
      const decrypted = CryptoJS.AES.decrypt(encrypted, this.ENC_KEY).toString(
        CryptoJS.enc.Utf8
      );
      return this.assign(JSON.parse(decrypted));
    } catch (error) {
      return new Cart();
    }
  }
}
