import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'imgBat'
})
export class ImgBatPipe implements PipeTransform {
  transform(uuid: any,custom:any ): any {
    if(custom === true){
      return 'assets/imgs/cutom-bat.png'
    } else {
      const apiUrl = environment.apiUrl;
      return `${apiUrl}/ecommerce/getImage?name=${uuid}`;
    }
  }

}
