import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { LayoutComponent } from "./layout/layout.component";
import { RouterModule } from "@angular/router";
import { AppRoutes } from "./app.routing";
import { ToastrModule } from "ng6-toastr-notifications";
import { PartialsModule } from "./partials/partials.module";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { PipesModule } from "./pipes/pipes.module";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { LangModalModule } from "./shared/modals/lang-modal/lang-modal.module";
import { SubscriptionModalModule } from "./shared/modals/subscription-modal/subscription-modal.module";

const modalModules = [LangModalModule, SubscriptionModalModule];

@NgModule({
  declarations: [AppComponent, LayoutComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes, {
      onSameUrlNavigation: "reload",
      scrollPositionRestoration: "enabled",
    }),
    NgxSmartModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(),
    PartialsModule,
    PipesModule,
    ...modalModules,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, "./assets/i18n/");
}
