import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd } from "@angular/router";
import { StoreService } from "./services/store.service";
import { CartService } from "./services/cart.service";
import { CheckoutService } from "./services/checkout.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { LanguageService } from "./services/language.service";
import { CategoriasService } from "./services/categorias.service";
import { ArticulosService } from "./services/articulos.service";
import { switchMap } from "rxjs/operators";
import { Pais } from "./enums";
import { CartService as CartStoreService } from "./services/cart/cart.service";
import {
  LANG_MODAL_ID,
  LangModalComponent,
} from "./shared/modals/lang-modal/lang-modal.component";
import {
  SUSCRIPTION_MODAL_ID,
  SubscriptionModalComponent,
} from "./shared/modals/subscription-modal/subscription-modal.component";
import { SuscripcionesService } from "./services/suscripciones.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private router: Router,
    private storeService: StoreService,
    private languageService: LanguageService,
    private cartService: CartService,
    public translate: TranslateService,
    private modalService: NgxSmartModalService,
    private checkoutService: CheckoutService,
    private _categorias: CategoriasService,
    private _articulos: ArticulosService,
    private _cart: CartStoreService,
    private _suscripciones: SuscripcionesService
  ) {
    translate.setDefaultLang("en");
    // this.translate.use("en");

    this.storeService.lineasObservable$.subscribe(() => {
      this.initLineasByLang();
    });
  }

  ngOnInit() {
    this.cartService.loadCart();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      } else {
        this.storeService.scrollTo(0);
        evt.url === "/checkout" || evt.url === "/checkout/success"
          ? this.checkoutService.$subjectCheckout.next(true)
          : this.checkoutService.$subjectCheckout.next(false);
      }
    });
    this.storeService.initLineas();
  }

  ngAfterViewInit() {
    const lang = this.languageService.init();
    if (!lang) this.openLangModal();
    else this.openSubscriptionModal();
    this.initCategorias();
  }

  ngOnDestroy() {
    localStorage.removeItem("bat");
  }

  selectLang(lang: "en" | "es") {
    this.cartService.clearCart();
    this._cart.remove();
    this.languageService.language$.next(lang);
    this.modalService.getModal("langModal").close();
  }

  initLineasByLang() {
    this.storeService.lineas =
      this.translate.currentLang == "es"
        ? this.storeService.lineasMX
        : this.storeService.lineasUSA;
  }

  initCategorias() {
    this.languageService.language$
      .pipe(
        switchMap((lang) =>
          this._articulos.categorias(
            lang == "es" ? Pais.Mexico : Pais.EstadosUnidos
          )
        )
      )
      .subscribe((categorias) => {
        this._categorias.categorias = categorias;
      });
  }

  openLangModal() {
    this.modalService
      .create(LANG_MODAL_ID, LangModalComponent, {
        closable: false,
        escapable: false,
        dismissable: false,
        customClass: "lang-modal",
      })
      .open()
      .onAnyCloseEventFinished.subscribe(() => {
        this.openSubscriptionModal();
      });
  }

  openSubscriptionModal() {
    if (!this._suscripciones.suscripcion)
      setTimeout(() => {
        this.modalService
          .create(SUSCRIPTION_MODAL_ID, SubscriptionModalComponent, {
            closable: true,
            customClass: "sub-modal",
          })
          .open()
          .onAnyCloseEventFinished.subscribe(() => {
            this._suscripciones.suscripcion = "SKIPED";
            this.modalService.removeModal(SUSCRIPTION_MODAL_ID);
          });
      }, 2000);
  }
}
