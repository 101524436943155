import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full'},
      {
        path: 'home',
        loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'store',
        loadChildren: () => import('./components/store/store.module').then(m => m.StoreModule),
      },
/*       {
        path: 'team',
        loadChildren: './components/team/team.module#TeamModule',
      }, */
      {
        path: 'contact',
        loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule),
      },
      {
        path: 'about',
        loadChildren: () => import('./components/about/about.module').then(m => m.AboutModule),
      },
      {
        path: 'custom',
        loadChildren: () => import('./components/custom/custom.module').then(m => m.CustomModule)
      },
      {
        path: 'checkout',
        loadChildren: () => import('./components/checkout/checkout.module').then(m => m.CheckoutModule),
      },
      {
        path: 'shopping',
        loadChildren: () => import('./components/shopping/shopping.module').then(m => m.ShoppingModule)
      },
      {
        path: 'categories/:id',
        loadChildren: () => import('./components/articles/category/category.module').then(m => m.CategoryModule)
      },
      {
        path: 'article/:id',
        loadChildren: () => import('./components/articles/article-detail/article-detail.module').then(m => m.ArticleDetailModule)
      }
    ]
  },
  { path: '**', component: NotFoundComponent }

];
