import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Route, Router, Scroll } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CheckoutService } from "../services/checkout.service";
import { CartService } from "../services/cart.service";
import { ContactoService } from "../services/contacto.service";
import { StoreService } from "../services/store.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { LanguageService } from "../services/language.service";
import { ArticulosService } from "../services/articulos.service";

import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Categoria } from "../shared/models/Categoria.model";
import { CategoriasService } from "../services/categorias.service";
import { CartService as CartStoreService } from "../services/cart/cart.service";
declare var $: any;

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild("navbar") navbar: ElementRef<HTMLElement>;
  @HostListener("window:scroll")
  onScroll() {
    this.navbarOnTop = !window.scrollY;
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.onChangeMenu();
  }

  private readonly onDestroy$ = new Subject<void>();

  public navbarOnTop: boolean = true;
  isCheckout = false;
  public menuAbierto = false;
  public showMenu = false;
  public lineas = [];

  public categorias!: Categoria[];

  constructor(
    public translate: TranslateService,
    private checkoutService: CheckoutService,
    private storeService: StoreService,
    private cartService: CartService,
    private contactoService: ContactoService,
    private languageService: LanguageService,
    private router: Router,
    private _categorias: CategoriasService,
    private _cart: CartStoreService
  ) {
    this.checkoutService.$observerCheckout.subscribe((checkout) => {
      this.isCheckout = checkout;
    });
  }

  ngOnInit() {
    this.onChangeMenu();
    this.router.events.subscribe(() => {
      this.onChangeMenu();
    });
    this.getCategorias();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getCategorias() {
    this._categorias.onCategoriasChange$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((categorias) => (this.categorias = categorias));
  }

  useLanguage(language: string) {
    if (language === this.translate.currentLang) {
      return;
    } else {
      this._cart.remove();
      this.cartService.clearCart();

      this.languageService.language$.next(language);
      setTimeout(() => {
        this.storeService.lenguajeObservable$.next(language);
        this.cartService.recalcularPrecio();
        this.contactoService.cambioIdioma$.next();
      }, 500);
    }
  }

  onChangeMenu() {
    if (window.innerWidth > 991) this.showMenu = true;
    else this.showMenu = false;
  }
}
