import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SubscriptionModalComponent } from "./subscription-modal.component";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [SubscriptionModalComponent],
  imports: [
    CommonModule,
    NgxSmartModalModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [SubscriptionModalComponent],
})
export class SubscriptionModalModule {}
