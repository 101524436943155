import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Categoria } from "../shared/models/Categoria.model";
import { LanguageService } from "./language.service";

@Injectable({
  providedIn: "root",
})
export class CategoriasService {
  private _categorias$ = new BehaviorSubject<Categoria[]>([]);
  public onCategoriasChange$ = this._categorias$.asObservable();

  constructor(private _language: LanguageService) {}

  get categorias() {
    return this._categorias$.value;
  }

  set categorias(categorias: Categoria[]) {
    this._categorias$.next(
      categorias.map((cat) => ({
        ...cat,
        nombre: cat[this._language.language$.value],
      }))
    );
  }
}
