import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { StoreService } from "./store.service";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  public language$ = new BehaviorSubject<string>("");
  private readonly KEY = "lang_overfly";

  constructor(
    private _store: StoreService,
    public _translate: TranslateService
  ) {}

  public init() {
    const lang = localStorage.getItem(this.KEY);
    this.onChange();
    if (!lang) return;
    if (!(lang === "en" || lang === "es")) return;
    this.language$.next(lang);
    return lang;
  }

  private onChange() {
    this.language$.subscribe((value) => {
      if (value === "en" || value === "es") {
        localStorage.setItem(this.KEY, value);
        this._translate.use(value);
        this._store.lineas = value == 'es' ? this._store.lineasMX : this._store.lineasUSA;
      }
    });
  }
}
