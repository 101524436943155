import {
  Component,
  OnInit,
  ChangeDetectorRef,
  DoCheck,
  OnDestroy,
} from "@angular/core";
import { CartService } from "../../services/cart.service";
import { Subject } from "rxjs";
import { Router, RouterEvent, NavigationEnd } from "@angular/router";
import { takeUntil, filter } from "rxjs/operators";
import { CartService as CartStoreService } from "../../services/cart/cart.service";

declare var $: any;
@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit, DoCheck, OnDestroy {
  public carritoItems = [];
  public itemsQty = 0;
  public total = 0;
  private unsubscribe$: Subject<any> = new Subject<any>();
  public showCart = false;

  constructor(
    private cartService: CartService,
    private cd: ChangeDetectorRef,
    private router: Router,
    public _cart: CartStoreService
  ) {
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(() => {});
    this.carritoItems = this.cartService.items || [];
    this.total = this.cartService.total;
    this.itemsQty = this.carritoItems.length;
    this.cartService.loadCart();
    this.cartService.carrito$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((items: any) => {
        if (items.length > 0) {
          this.carritoItems = items;
          this.total = this.cartService.total;
          this.itemsQty = this.carritoItems.length;
        } else {
          this.carritoItems = [];
          this.total = 0;
          this.itemsQty = 0;
        }
      });
  }

  get hasItems() {
    return (
      (this.carritoItems && this.carritoItems.length) || !this._cart.isEmpty
    );
  }

  ngOnInit() {}

  ngDoCheck() {
    this.cd.markForCheck();
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  openList(e) {
    if (this.cartService.innerWidth > 421) {
      this.mostrarDrop();
    } else {
      this.router.navigate(["/shopping"]);
    }
  }
  mostrarDrop() {}
  trackByFn(index, item) {
    return index; // or item.id
  }
}
