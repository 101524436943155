import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Categoria } from "../shared/models/Categoria.model";
import { Articulo } from "../shared/models/Articulo.model";
import { map, switchMap } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { of } from "rxjs";
import { Pais } from "../enums";

@Injectable({
  providedIn: "root",
})
export class ArticulosService {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  categorias(pais: Pais = Pais.EstadosUnidos) {
    return this.http.get<Categoria[]>(
      `${environment.apiUrl}/categorias-articulos/store`,
      { params: { pais } }
    );
  }

  categoria(id: number) {
    return this.http.get<Categoria>(
      `${environment.apiUrl}/categorias-articulos/${id}`
    );
  }

  articulos(
    categoria?: number,
    opts: { page?: number; limit?: number; pais?: Pais } = {}
  ) {
    return this.http
      .get<[Articulo[], number]>(`${environment.apiUrl}/articulos`, {
        params: {
          categoria,
          page: opts.page || "",
          limit: opts.limit || "",
          pais: opts.pais || "",
        },
      })
      .pipe(
        switchMap(([articulos, count]) =>
          of(<[Articulo[], number]>[
            articulos.map((articulo) => this.mapArticuloImages(articulo)),
            count,
          ])
        )
      );
  }

  articulosIds(ids: number[]) {
    return this.http.get<Articulo[]>(`${environment.apiUrl}/articulos/ids`, {
      params: { ids: JSON.stringify(ids) },
    });
  }

  articulo(id: number, opts: { pais?: Pais } = {}) {
    return this.http
      .get<Articulo>(`${environment.apiUrl}/articulos/${id}`, {
        params: { pais: opts.pais || "" },
      })
      .pipe(map((articulo) => <Articulo>this.mapArticuloImages(articulo)));
  }

  private sanitizeFile(id: number | string): any {
    return `${environment.apiUrl}/uploaded-files/${id}?type=articulos`;
  }

  private mapArticuloImages(articulo: Articulo) {
    return {
      ...articulo,
      imagen: articulo.imagen ? this.sanitizeFile(articulo.imagen.id) : null,
      imagenes:
        articulo.imagenes && articulo.imagenes.length
          ? articulo.imagenes.map((imagen) => this.sanitizeFile(imagen.id))
          : [],
      colores:
        articulo.colores && articulo.colores.length
          ? articulo.colores.map((color) => ({
              ...color,
              imagenes:
                color.imagenes && color.imagenes.length
                  ? color.imagenes.map((imagen) => this.sanitizeFile(imagen.id))
                  : [],
            }))
          : [],
    };
  }
}
