import { CartItem } from "./CartItem.class";

export class Cart {
  items: CartItem[];

  constructor(items?: CartItem[]) {
    this.items = items || [];
  }

  get quantity() {
    return this.items.reduce((acc, { quantity }) => acc + quantity || 0, 0);
  }

  get discount() {
    return this.items.reduce(
      (acc, { totalDiscount }) => acc + totalDiscount,
      0
    );
  }

  get subtotal() {
    return this.items.reduce(
      (acc, { totalPrice }) => acc + (totalPrice || 0),
      0
    );
  }
  get total() {
    return this.items.reduce(
      (acc, { finalTotalPrice }) => acc + (finalTotalPrice || 0),
      0
    );
  }
}
