import { NgModule } from '@angular/core';
import { ErrorHandlerPipe } from './error-handler.pipe';
import { ImgBatPipe } from './img-bat.pipe';

@NgModule({
  imports: [
  ],
  declarations: [
    ErrorHandlerPipe,
    ImgBatPipe
  ],
  exports: [
    ErrorHandlerPipe,
    ImgBatPipe
  ]
})
export class PipesModule { }
