import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LangModalComponent } from "./lang-modal.component";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [LangModalComponent],
  imports: [CommonModule, NgxSmartModalModule, TranslateModule],
  exports: [LangModalComponent],
})
export class LangModalModule {}
