import { TranslateService } from '@ngx-translate/core';
import { Injectable, HostListener } from '@angular/core';
import { ItemCart } from '../interfaces/item-cart';
import { Cart } from '../interfaces/cart';
import { Observable, BehaviorSubject } from 'rxjs';
import {SimpleCrypt} from 'ngx-simple-crypt';
import { ToastrManager } from 'ng6-toastr-notifications';
import { publishLast, distinct } from 'rxjs/operators';
import { ItemCustom } from '../interfaces/item-custom';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public cart: Cart;
  public items: ItemCart [] = [];
  private simpleCrypt = new SimpleCrypt();
  public carrito$ = new  BehaviorSubject<any>(this.items);
  public total = 0;
  public subTotal = 0;
  public innerWidth = window.innerWidth;
  public divisa = 'USD';
  public envio = 0;
  public descontado = 0;
  public success = false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
  this.innerWidth = window.innerWidth;
}
protected key = 'it%G|d+}_RR!hQgN#?^x<(@g]6&?Jz';
  constructor(private toastr: ToastrManager, private translateService: TranslateService) {
  }

  addItem(item: (ItemCart)){
    if(this.items.length > 0){
      if(this.items.every( i => item.id != i.id ) && this.items.length >= 1){
        this.items.push(item);
      }else{
        this.items.forEach( itemCart => {
          if (item.id === itemCart.id) {
            itemCart.cantidad += item.cantidad;
          }
      });
    }
      this.calcularTotal(false,null);
      this.successToastrLang( 'Item added successfully', 'Producto añadido correctamente');
      this.carrito$.next(this.items);
    } else {
      this.items.push(item);
      this.calcularTotal(false, null);
      this.successToastrLang( 'Item added successfully', 'Producto añadido correctamente');
      this.carrito$.next(this.items);
    }
    this.setLSItems();
  }
  removeItem(index){
    this.calcularTotal(true, index);
    this.items.splice(index, 1);
    this.carrito$.next(this.items);
    this.errorToasterLang('Item deleted successfully', 'Producto eliminado correctamente');
    this.setLSItems();
  }
  substractQty(item){
    this.items.forEach(i => {
      if(i.id === item.id){
        i.cantidad--;
        this.calcularTotal(false,null);
        this.setLSItems();
      this.errorToasterLang('Item deleted successfully', 'Producto eliminado correctamente');
        this.carrito$.next(this.items);

      }
    });
  }
  addQty(item){
    this.items.forEach(i => {
      if(i.id === item.id){
        i.cantidad++;
        this.calcularTotal(false,null);
        this.setLSItems();
        this.successToastrLang( 'Item added successfully', 'Producto añadido correctamente');
        this.carrito$.next(this.items);

      }
    });

  }
  loadCart(){
    if(localStorage.getItem('cart')) {
      this.items = this.decryptCart();
      this.calcularTotal(false, null);
      this.carrito$.next(this.items);
    } else {
      this.cart = null;
      this.items = [];
    }
  }

  clearCart(){
    localStorage.clear();
    this.items = [];
    this.total = 0;
    this.subTotal = 0;
    this.carrito$.next(this.items);

  }

  calcularEnvio(language,cantidad){
    if(language === 'en' || language === 'USD'){
      switch (true) {
        case (cantidad >= 1 && cantidad <= 2):
          this.envio = 19;
          break;
        case (cantidad >= 3 && cantidad <= 4):
          this.envio = 29;
          break;

        case (cantidad >= 5 && cantidad <= 12):
          this.envio = 41;
          break;

      }
    } else {
      if(language === 'es' || language === 'MXN') {
        switch (true) {
          case (cantidad >= 1 && cantidad <= 4):
            this.envio = 300;
            break;
          case (cantidad >= 5 && cantidad <= 8):
            this.envio = 350;
            break;

          case (cantidad >= 9 && cantidad <= 12):
            this.envio = 600;
            break;
        }
      }
    }
  }
  calcularTotal(eliminar, i){
    if(this.items.length === 0){
      return this.total = 0;
    }
    this.items.forEach( (item: any) => {
      item.precio = this.translateService.currentLang === 'en' ? item.precioUsd : item.precioMxn;
    });
    if(eliminar){
      this.total -= this.items[i].precio * this.items[i].cantidad;
      this.subTotal -= this.items[i].precio * this.items[i].cantidad;
    }else{
      var cantidad = 0;
      this.items.forEach((item: ItemCart) => {
        cantidad += item.precio * item.cantidad;
      });
      this.total = cantidad;
      this.subTotal = cantidad;
    }
  }


  private setLSItems(){
    const encodedString = this.simpleCrypt.encode(this.key,JSON.stringify(this.items));
    localStorage.setItem('cart',JSON.stringify(encodedString));
  }

  decryptCart(): ItemCart[] {
    const cart = JSON.parse(localStorage.getItem('cart'));
    const decodedString = this.simpleCrypt.decode(this.key,cart);
    return JSON.parse(decodedString);
  }

  recalcularPrecio() {
    this.items.forEach( (item: any) => {
      item.precio = this.translateService.currentLang === 'en' ? item.precioUsd : item.precioMxn;
      item.color = this.translateService.currentLang === 'en' ? item.colorEn : item.colorEs;
    });
    this.calcularTotal(false, 0);
    this.carrito$.next(this.items);
  }

  successToastrLang( mensajeEn, mensajeEs?){
    this.translateService.currentLang === 'en' ?
      this.toastr.successToastr(mensajeEn, '', {toastTimeout: 2500, dismiss: 'auto',maxShown: 1, position: 'bottom-right'}) :
      this.toastr.successToastr(mensajeEs, '', {toastTimeout: 2500, dismiss: 'auto',maxShown: 1, position: 'bottom-right'});
  }
  errorToasterLang(mensajeEn, mensajeEs?){
    this.translateService.currentLang === 'en' ?
      this.toastr.errorToastr(mensajeEn, '', {toastTimeout: 2500, dismiss: 'auto',maxShown: 1, position: 'bottom-right'}) :
      this.toastr.errorToastr(mensajeEs, '', {toastTimeout: 2500, dismiss: 'auto',maxShown: 1, position: 'bottom-right'});
  }

}
