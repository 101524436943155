import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { StoreService } from '../../services/store.service';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-random-bats',
  templateUrl: './random-bats.component.html',
  styleUrls: ['./random-bats.component.scss']
})
export class RandomBatsComponent implements OnInit, OnDestroy {
  public bats = [];
  @Input() linea: any = '';
  constructor(
    private storeService: StoreService,
    private router: Router,
    private translate: TranslateService,
    private languageService: LanguageService) { }

  ngOnInit() {
    this.onLangChange();
  }
  ngOnDestroy(){
    this.bats = [];
  }

  irDetalle(bat) {
    this.storeService.batData = {
      id: bat.idModelo,
      linea: bat.lineaId,
      nombre: bat.nombre,
    };
    this.router.navigate([
      "/store",
      bat.lineaId,
      bat.nombre.toLowerCase().replace(/ /g, "-"),
      `?i=${bat.idModelo}&l=${bat.lineaId}&n=${bat.nombre}`,
    ]);



    // this.storeService.batData = {id: bat.modelo, linea: bat.lineaId, nombre: bat.nombre};
    // this.router.navigate(['/store', bat.linea,  bat.nombre.toLowerCase().replace(/ /g, '-'),`?i=${bat.modelo}&l=${bat.lineaId}&n=${bat.nombre}`]);
    this.getBats(this.translate.currentLang);
  }

  getBats(lang) {
    this.storeService.getRandomBats(lang).subscribe( (bats: any) => {
      bats.map(bat => {
        bat.precio = this.translate.currentLang === 'en' ? bat.precioUsd : bat.precioMxn;
      });
      this.bats = bats;
    });
  }

  onLangChange() {
    this.languageService.language$.subscribe((value) => {
      this.getBats(value);
    });
  }
}
